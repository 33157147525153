import { create } from '@storybook/theming/create';

export default create({
  base: 'light',
  brandTitle: 'Toitū Te Whenua - Land Information New Zealand',
  brandUrl: 'https://www.linz.govt.nz/',
  brandImage: 'favicon.svg',
  brandTarget: '_blank',
  // UI
  appBg: '#ffffff',
  appContentBg: '#ffffff',
});
